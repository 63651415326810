import { Link } from "gatsby";
import React from "react";

export default function allergy() {
  return (
    <>
      <section className="allergy">
        <div className="layer">
          <div className="content pine">
            <a
              href="https://www.google.com/search?q=%EC%9E%A3"
              target="_blank"
              rel="noreferrer"
            >
              🚫
              <br />잣
            </a>
          </div>
        </div>
      </section>
      <section className="block absolute-bottom">
        <ul className="big-link-list">
          <li className="text-align-center">
            <Link to="/">🍙</Link>
          </li>
        </ul>
      </section>
    </>
  );
}
